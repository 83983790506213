// Set file variable
$filename: 'scss/custom/_base-widgets.scss';

/*==============================================================================
  @Base Widgets - #{$filename}
==============================================================================*/

/**
 * Global Widgets
 */

.widget {
  margin: 1.5rem 0;
  padding: 1em 2em;
  background: $bg-widget;
  background: var( --bg-widget, var(--shade, $bg-widget));
  border: $widget-border;
  border-radius: $border-radius;
  font-size: px-to-em(14px);
  line-height: 1.5em;

  &.outline {
    background: none;
    border: 1px solid rgba($black, 0.1);
  }
  &.naked {
    padding: 0;
    background: none;
  }
}

.sidebar .widget {
  text-align: center;
}

/*==============================================================================
  @Widget Content - #{$filename}
==============================================================================*/

.widget {

  h2,
  h3 {

    a {
      color: $color;
    }

    a:hover {
      color: $color-link-hover;
      color: var(--color-link-hover, var(--accent, $color-link-hover));
    }
  }

  h3 {
    font-size: px-to-em(18px, 14px);
    font-weight: font-weight('semi-bold');
  }

  .phone {
    font-size: 1.2rem;
    line-height: 1.5em;
  }
}

/**
 * Widget Buttons
 */

.widget {

  .button {
    display: block;
    line-height: px-to-em(25px, 14px);
    @include add-icon-button('left');

    + .button {
      margin-top: 0.5em;
    }
  }

  .button.inline {
    float: none;
    display: inline-block;
    margin: 0;

    + .button {
      margin-left: 0.5em;
    }
  }

}

/*==============================================================================
  @Base Widget Types - #{$filename}
==============================================================================*/

/**
 * Sub Navigation
 */

.widget-nav {
  h3 {
    margin: 0.5em 0;
  }

  ul {
    list-style: none;
    margin: 1em 0;
  }

  ul ul {
    margin-left: 1em;
  }

  ul li {
    margin: 1px 0;
  }

  ul li a {
    display: block;
    padding: 0.5em 1em;
    border: none;
    font-size: px-to-em(16px, 14px);
    color: $color;

    &:hover {
      background: $bg-main;
      background: var(--bg-main, $bg-main);
      border-radius: $border-radius;
      color: $color;
      text-decoration: none;
    }
  }

  ul > li.current_page_item > a,
  ul > li.current_page_parent > a,
  ul > li.current_page_ancestor > a {
    color: $color-link-hover;
    color: var(--color-link-hover, var(--accent, $color-link-hover));
  }
}

.sidebar .widget-nav {
  text-align: left;
}
