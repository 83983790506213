// Set file variable
$filename: 'scss/vendor/_fittingbox.scss';

/*==============================================================================
  @Fittingbox - #{$filename}
==============================================================================*/

.fittingbox-wrapper {
  position: relative;
  padding-bottom: 0px;
  padding-top: 0px;
  height: 640px;
  min-height: 640px;
}

.fittingbox-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
