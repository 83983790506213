// Set file variable
$filename: 'scss/custom/_featured-blog.scss';

/*==============================================================================
  @Featured Blog - #{$filename}
==============================================================================*/

.featured-blog {
  padding: 1em 0;
  text-align: center;

  ul {
    list-style: none;
    margin-top: 1em;
    margin-bottom: 1em;
  }

  ul li {
    margin: 1em 0;
  }

  img {
    display: inline-block;
    margin: 0 auto;
  }

  h3 {
    font-size: px-to-em(18px);
    font-weight: font-weight('semi-bold');
  }

  a {
    color: $color;

    &:hover {
      color: $accent;
      color: var(--accent, $accent);
    }
  }
}

@include media-min('medium') {
  .featured-blog {
    padding: 2em 0;

    ul {
      margin-top: 2em;
      margin-bottom: 2em;
    }

    ul li {
      margin: 0;
    }
  }
}

/**
 * Styles
 */

.featured-blog {
  background: $white;

  img {
    border-radius: $border-radius;
  }

  .timestamp {
    color: $color-light;
    font-style: italic;
  }
}
