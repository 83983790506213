// Set file variable
$filename: 'scss/vendor/_gravityforms.scss';

/*==============================================================================
  @Gravity Forms - #{$filename}
==============================================================================*/

.main .gform_wrapper {
  margin: 1em 0;
  padding: 0.5em 1em;
  border: 1px solid rgba($black, 0.1);
  border-radius: $border-radius;

  form {
    margin: 0;
  }
  label {
    margin: 0;
  }

  ul.gform_fields li.gfield {
    padding: 0.5em 0;
    margin: 0;
    border-top: 1px solid rgba($black, 0.1);

    &:first-child {
      border-top: 0 none;
    }
  }
  .gsection {
    border: none;
  }
  form ul.gform_fields.left_label li.gfield,
  form ul.gform_fields.right_label li.gfield,
  ul.gform_fields.left_label li.gfield,
  ul.gform_fields.right_label li.gfield {
    margin: 0;
  }
  .gform_footer {
    margin: 0;
    border-top: 1px solid rgba($black, 0.1);
  }

  img {
    margin: 0;
  }

  // Submit Button
  .gform_footer input.button,
  .gform_footer input[type=submit],
  .gform_page_footer input.button,
  .gform_page_footer input[type=submit] {
    @extend .button.primary;
    width: 100%;
    margin: 0;
  }
}

@include media-min('medium') {
  .main .gform_wrapper {
    // Submit Button
    .gform_footer input.button,
    .gform_footer input[type=submit],
    .gform_page_footer input.button,
    .gform_page_footer input[type=submit] {
      width: auto;
    }
  }
}
