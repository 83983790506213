// Set file variable
$filename: 'scss/custom/_listing-subpages.scss';

/*==============================================================================
  @Listing Subpages - #{$filename}
==============================================================================*/

.listing-subpages {
  list-style: none;
  margin: 2em 0;

  li {
    margin: 1em 0;
  }
}

.listing-subpages a {
  display: block;
  padding: 0.5em 1.5em;
  color: $color;
  border: 1px solid rgba($black, 0.1);
  border-radius: $border-radius;
  transition: $transition;
  box-shadow: 0 0 0 rgba($black, 0);

  h3 {
    color: $color;
    transition: $transition;
  }

  .icon {
    float: right;
    margin: -1px 0 0 1em;
    font-size: 1.2em;
    color: $accent;
    color: var(--accent, $accent);
  }

  &:hover {
    text-decoration: none;
    border-color: $accent;
    border-color: var(--accent, $accent);
    box-shadow: 0 1px 6px rgba($black, 0.1);

    h3 {
      color: $accent;
      color: var(--accent, $accent);
    }
  }
}

@include media-min('medium') {
  .listing-subpages a {
    padding: 1em 2em;
  }
}
