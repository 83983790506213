// Set file variable
$filename: 'scss/custom/_tpl-blog.scss';

/*==============================================================================
  @Blog - #{$filename}
==============================================================================*/

/**
 * Post Has Thumbnail
 */
.post-has-thumbnail {
  @include clearfix();

  .post-thumbnail {
    float: left;
    max-width: 40%;
    max-height: auto;
    margin: 0 1.5em 1em 0;

    img {
      margin: 0;
    }
  }
}

@include media-min('medium') {
  .post-has-thumbnail {
    .post-thumbnail {
      max-width: 200px;
      max-height: 200px;
    }

    .post-content {
      padding-left: px-to-em(230px);
    }
  }
}

/**
 * Post Action
 */
.post-action {
  clear: both;
  font-size: px-to-em(14px);
  text-align: center;

  .button {
    display: block;
    width: 100%;
  }

  .timestamp {
    display: block;
    color: $color-light;
    font-style: italic;
    margin: 1em 0 0 0;
  }
}

@include media-min('medium') {
  .post-action {
    clear: none;
    display: flex;
    align-items: center;
    text-align: left;

    .button {
      display: inline-block;
      width: auto;
    }
    
    .timestamp {
      display: inline-block;
      margin: 0 0 0 1.5em;
    }
  }
}

/**
 * Post Meta
 */
.post-meta {
  padding: 1em 2em;
  border: 1px solid rgba($black, 0.1);
  border-radius: $border-radius;
  text-align: center;
  font-size: px-to-em(14px);

  .social-links {
    justify-content: center;
  }
}
