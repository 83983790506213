// Set file variable
$filename: 'scss/custom/_off-canvas.scss';

/*==============================================================================
  @Off-Canvas - #{$filename}
==============================================================================*/

.oc-header {
  text-align: right;

  .close {
    display: inline-block;
    padding: 1em;
    line-height: 1em;
    color: $color;
  }
  .close .icon {
    font-size: 16px;
  }
}

.oc-nav {
  ul {
    margin: 0 1em;
    list-style: none;
  }

  ul ul {
    margin-right: 0;
    display: none;
  }

  ul li {
    position: relative;
    margin: 0;
  }

  a {
    display: block;
    padding: 0.5em 1em;
    color: $color-light;
  }

  a:visited {
    color: $color-light;
  }

  a:hover,
  &:focus {
    color: $accent;
    color: var(--accent, $accent);
    text-decoration: none;
  }

  > ul > li + li {
    border-top: 1px solid rgba($black, 0.1);
  }

  .current_page_item > a,
  .current_page_parent > a {
    color: $color;
  }

  .page_item_has_children > a {
    padding-right: 40px;
  }

  .current_page_parent > ul {
    display: block;
  }
}

.children-toggle {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  background: rgba($black, 0);
  cursor: pointer;
  transition: all 0.25s;

  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -1px 0 0 -2px;
    @include make-triangle-base();
    @include make-triangle( 4px, $color-light, 'down' );
  }
}
