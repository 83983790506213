// Set file variable
$filename: 'scss/custom/_nav-posts.scss';

/*==============================================================================
  @Nav Posts - #{$filename}
==============================================================================*/

.nav-posts {
  margin: 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  a {
    position: relative;
    @extend .button;
    @extend .button.outline;
    width: 100%;
    display: block;
    text-align: left;
    padding: 1em 1.5em 1em 4.5em;

    .icon {
      position: absolute;
      top: 0.75rem;
      left: 1.5rem;
      margin: 0;
    }
  }
  
  a + a {
    margin-top: 1em;
  }
}
