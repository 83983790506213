// Set file variable
$filename: 'scss/custom/_social-links.scss';

////////////////////////////////////////////////////////////////////////////////
// @Listing People Variables
////////////////////////////////////////////////////////////////////////////////

$custom_social: (
  'google' : #dd4b39,
  'yelp' : #d32323,
);

$social: map-extend($social, $custom_social, true);

/*==============================================================================
  @Social Links - #{$filename}
==============================================================================*/

.social-links {
  display: flex;
  flex-wrap: wrap;
  margin: 10px -5px 5px 0;
  padding: 0;
  list-style: none;
  @include clearfix();

  li {
    margin: 0 5px 5px 0;
    padding: 0;
    list-style: none;
    font-size: 0;
    line-height: 0;
  }

  li a {
    background: $white;
    display: block;
    margin: 0;
    border: 0 none;
    overflow: hidden;

    &:hover {
      opacity: 0.75;
      text-decoration: none;
    }
  }

  .icon {
    margin: 0;
    font-size: 28px;
  }
  
  @each $key, $value in $social {
    .icon-#{$key} {
      color: $value;
    }
  }
}
