// Set file variable
$filename: 'scss/custom/_dropdowns.scss';

/*==============================================================================
  @Dropdowns - #{$filename}
==============================================================================*/

.nav-menu li {
  @include make-dropdown-trigger((
    'target' : '> ul',
    'target-action' : '&:hover > ul'
  ));
  @include add-dropdown-position((
    'target' : '> ul',
    'target-action' : '&:hover > ul'
  ), $anchor: 'bottom-center');
}
.nav-menu ul {
  @include make-dropdown();
  @include add-dropdown-style($type: 'dropdown-menu');
  li {
    margin: 1px 0;
  }
  li.current_page_item a,
  li.current_page_parent a,
  li.current_page_ancestor a {
    color: $color-link;
    color: var(--color-link, $color-link);
  }
}

/* 3rd Level Nav */
.nav-menu ul li {
  @include add-dropdown-position((
    'target' : '> ul',
    'target-action' : '&:hover > ul',
    'offset-position' : -0.5em
  ), $anchor: 'right-top');
}

/**
 * Dropdown Carrots
 */
.nav-menu > li > ul {
  &:before,
  &:after {
    @include make-triangle-base();
    position: absolute;
    left: 50%;
    bottom: 100%;
    margin-left: -8px;
  }
  &:before {
    @include make-triangle( 8px, rgba($black, 0.1), 'up' );
    margin-bottom: 1px;
  }
  &:after {
    @include make-triangle( 8px, $white, 'up' );
  }
}
