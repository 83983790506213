// Set file variable
$filename: 'scss/custom/_listing-promotions.scss';

/*==============================================================================
  @Listing Promotions - #{$filename}
==============================================================================*/

.listing-promotions {
  a {
    position: relative;
    display: block;
    padding: 1em;
    border-radius: $border-radius;
    transition: $transition;

    &:hover {
      background: $bg-widget;
      background: var(--bg-body, $bg-widget);
      text-decoration: none;
    }
  }
  h3,
  p {
    margin: 0;
  }
  h3 {
    margin: 1rem 1rem 0.25rem 1rem;
    transition: $transition;
  }
  .experation-date {
    margin-bottom: 0.5em;

    .chip {
      transition: $transition;
    }
  }
  .notice p {
    margin: 0.5em 0;
  }
}
