// Set file variable
$filename: 'scss/custom/_header-action.scss';

/*==============================================================================
  @Action - #{$filename}
==============================================================================*/

.action-menu {
  display: flex;
  align-items: center;
  overflow: auto;
  margin: 0 -1.5em;
  padding: 0;
  background: $secondary;
  background: var(--secondary, $secondary);

  .button {
    flex: 1 0 auto;
    margin: 0;
    padding: 0.75em 1em;
    background: none;
    color: $white;
    border-radius: 0;
    @include add-icon-button('left');

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      color: $white;
      background: rgba($black, 0.1);
    }
  }

  .button-menu {
    flex: 0 0 auto;
  }

  .button.text {
    padding: 0;
    margin-right: 1em;
    background: none;
    font-size: 1.2em;

    &:hover {
      background: none;
    }
  }

  a[x-apple-data-detectors] {
    display: none;
  }
}

@include media-min('medium') {
  .action-menu {
    overflow: visible;
    margin: 0;
    padding: 0;
    background: none;

    .button {
      background: none;
      margin-right: 10px;
      padding: 0.75em 1.5em;
      background: rgba($black, 0.05);
      border: 1px solid transparent;
      border-radius: $border-radius;
      color: $color;

      &:hover,
      &:focus {
        background: $white;
        border-color: $white;
        color: $color;
      }
    }

    .button.text {
      border: none;
      background: none;
      color: $color;

      &:hover,
      &:focus {
        background: none;
        color: $color;
      }
    }
  }

  .text-light .action-menu {
    .button {
      background: rgba($black, 0.05);
      border-color: rgba($white, 0.5);
      color: $white;
      transition: none;

      &:hover,
      &:focus {
        background: $white;
        border-color: $white;
        color: $color;
        transition: none;
      }
    }

    .button.text {
      border: none;
      background: none;
      color: $white;

      &:hover,
      &:focus {
        background: none;
        color: $white;
      }
    }
  }
}
