// Set file variable
$filename: 'scss/custom/_custom.scss';

/*==============================================================================
  @Structure - #{$filename}
==============================================================================*/

/**
 * Main
 */

.home .main {
  background: $bg-main;
  background: var(--bg-main, var(--shade, $bg-main));
}

/**
 * Front-page Content
 */

@include media-min('medium') {
  .front-page-content {
    padding: 1.5em 0;
  }
}

/**
 * Listing Promotions
 */

.listing-promotions a:hover {
  .experation-date .chip {
    background-color: $white;
  }
}

/**
 * Media
 */

.media {
  display: flex !important;
  align-items: center;

  img {
    flex-grow: 0;
    margin: 0;
  }
}

.media-content {
  padding-left: 1.5em;
}

.featured-testimonials {
  .media {
    display: flex !important;
    flex-direction: column;
    text-align: center;
  }

  .media-content {
    padding-left: 0;
  }
}

@include media-min('medium') {
  .featured-testimonials {
    .media {
      flex-direction: row;
      align-items: center;
      text-align: left;

      img {
        flex-grow: 0;
        margin: 0;
      }
    }

    .media-content {
      padding-left: 1.5em;
    }
  }
}
