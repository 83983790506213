// Set file variable
$filename: 'scss/custom/_nav-pages.scss';

/*==============================================================================
  @Nav Pages - #{$filename}
==============================================================================*/

.nav-pages {
  margin: 2em 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
