// Set file variable
$filename: 'scss/custom/_featured-action.scss';

/*==============================================================================
  @Featured Action - #{$filename}
==============================================================================*/

/**
 * Animation
 */
@keyframes waddle {
  from {
    transform: scale3d(1, 1, 1);
  }
  20%, 60% {
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, 3deg);
  }
  40%, 80% {
    transform: scale3d(1.05, 1.05, 1.05) rotate3d(0, 0, 1, -3deg);
  }
  to {
    transform: scale3d(1, 1, 1);
  }
}

/**
 * Structure
 */
.featured-action {
  position: relative;
  z-index: 5;
  margin: 0;
  // background: $white;

  &:after {
    position: absolute;
    z-index: 1;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $bg-main;
    background: var(--bg-main, var(--shade, $bg-main));
  }

  .container {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }

  ul {
    position: relative;
    z-index: 2;
    list-style: none;
    margin: 0;
  }

  li {
    margin: 0;
  }
}

@include media-min('medium') {
  .featured-action {

    &:after {
      height: 50%;
      top: 50%;
    }

    .container {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    ul {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    ul li {
      display: flex;
      align-items: stretch;
      flex: 1 1 0;
    }

    ul li a {
      flex: 1 1 auto;
    }
  }
}

/**
 * List Items
 */
.featured-action {
  li {
    padding: 0.5em 0;
  }

  li + li {
    // border-top: 1px solid rgba($black, 0.1);
  }

  li:first-child {
    padding-top: 0;
  }

  li:last-child {
    padding-bottom: 0;
  }
}

@include media-min('medium') {
  .featured-action {
    li {
      padding: 0 0.5em;
    }

    li + li {
      border-top: none;
      // border-left: 1px solid rgba($black, 0.1);
    }

    li:first-child {
      padding-left: 0;
    }

    li:last-child {
      padding-right: 0;
    }
  }
}

/**
 * Links
 */
.featured-action a {
  display: block;
  padding: 1em;
  border: none;
  text-align: center;
  color: $color;
  background: $white;
  border-radius: $border-radius;

  animation-duration: 0.5s;
  animation-fill-mode: both;
  transition: $transition;

  &:hover {
    text-decoration: none;
    animation-name: waddle;

    h2 {
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  h2,
  p {
    transition: $transition;
    margin: 0;
  }

  h2 {
    font-size: px-to-em(22px);
    font-weight: font-weight('semi-bold');
    color: $color;
  }

  p {
    font-style: italic;
    color: $color-light;
  }

  .icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
  }

  .icon {
    margin: 0;
    font-size: 50px;
    color: $accent;
    color: var(--accent, $accent);
  }

  .content {
    min-width: 200px;
  }
}

@include media-min('medium') {
  .featured-action a {
    padding: 2em;

    .content {
      min-width: 0;
    }
  }
}

/**
 * Layout
 */

@mixin featured-action($layout: 'stack') {
  @if ($layout == 'stack') {
    .featured-action a {
      display: block;
      text-align: center;

      .icon-wrap {
        margin: 0 auto 1.5em;
      }
    }
  } @else if ($layout == 'flat') {
    .featured-action a {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      .icon-wrap {
        margin: 0 1em 0 0;
      }
    }
  } @else {
    @warn 'The layout style `#{$layout}` does not exist in the `featured-action` mixin.';
  }
}

@include featured-action('stack');

@include media-min(400px) {
  @include featured-action('flat');
}
@include media-min('medium') {
  @include featured-action('stack');
}
