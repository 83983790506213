// Set file variable
$filename: 'scss/custom/_listing-people.scss';

/*==============================================================================
  @Listing People - #{$filename}
==============================================================================*/

.listing-people {
  img {
    float: left;
    max-width: 30%;
    margin: 1em 1.5em 0.5em 0;
  }
  .button {
    @include add-icon-button('left');
  }
  .button-group {
    margin: 1em 0;
  }
}

@include media-min('medium') {
  .listing-people .button-group {
    @include add-button-group-display('inline');
    @include add-button-group-orientation('hori');
  }
}
