// Set file variable
$filename: 'scss/vendor/_wordpress.scss';

/*==============================================================================
  @WordPress - #{$filename}
==============================================================================*/

.oc-aside {
  z-index: 999999; // Need to make this sit on top of the WP admin bar
}

/**
 * Image Alignment
 */
.alignleft,
img.alignleft {
	display: inline;
	float: left;
	max-width: 50%;
	margin-right: 20px;
	margin-top: 10px;
}
.alignright,
img.alignright {
	display: inline;
	float: right;
	max-width: 50%;
	margin-left: 20px;
	margin-top: 10px;
}
.aligncenter,
img.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
img.alignleft,
img.alignright,
img.aligncenter {
	margin-bottom: 10px;
}

/**
 * Gallery Structure
 */
.gallery {
  margin: 2em 0;
  padding: 0.5em 0;
  border-top: 1px solid rgba($black, 0.1);
  border-bottom: 1px solid rgba($black, 0.1);
  @include clearfix();
}
.gallery .gallery-item {
  margin: 1rem 0;
	text-align: left;
	width: 100%;
  font-size: 0.9em;
  line-height: 1.5em;
}

@include media-min('small') {
  .gallery .gallery-item {
  	float: left;
    margin: 1rem 0;
  	text-align: center;
  }
  .gallery-columns-2 .gallery-item { width: 50%; }
  .gallery-columns-3 .gallery-item { width: 33%; }
  .gallery-columns-4 .gallery-item { width: 25%; }
  .gallery-columns-5 .gallery-item { width: 25%; }
  .gallery-columns-6 .gallery-item { width: 25%; }
  .gallery-columns-7 .gallery-item { width: 25%; }
  .gallery-columns-8 .gallery-item { width: 25%; }
  .gallery-columns-9 .gallery-item { width: 25%; }
}

/**
 * Captions
 */
.wp-caption-text {
  padding-bottom: 0.5em;
  font-size: 0.875em;
  line-height: 1.375;
  font-style: italic;
  color: $color-light;
  border-bottom: 1px solid rgba(0,0,0, 0.1);
}

/**
 * Gallery Items
 */
.gallery-item {
  display: flex;
  align-items: center;
}
.gallery-icon {
  flex: 1 0 33%;
  margin: 0 1.5em 0 0;

  img {
    margin: 0 auto;
  	padding: 0.5em;
  	background: rgba($black, 0.05);
  }
}
.gallery-caption {
  margin: 0;
  padding: 0;
  border-bottom: none;
}

@include media-min('small') {
  .gallery-item {
    display: block;
  }
  .gallery-icon {
    margin: 0 0.5em 0.5em;
  }
  .gallery-caption {
    margin: 0 0.5em;
  }
}

/**
 * Gallery Markup
 */
.gallery br {
  display: none;
}
