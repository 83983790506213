// Set file variable
$filename: 'scss/custom/_global.scss';

/*==============================================================================
  @Grid System - #{$filename}
==============================================================================*/

@include build-grid-system('mobile');

@include media-min('medium') {
  @include build-grid-system();
}

/*==============================================================================
  @Global - #{$filename}
==============================================================================*/

body {
  background: $bg-body;
  background: var(--bg-body, $bg-body);
}

/**
 * Headings
 */
h1 {
  font-size: px-to-em(26px);
}
h2 {
  font-size: px-to-em(22px);
}
h3 {
  font-size: px-to-em(18px);
}

// This is a weired case where we want the followup h2 from an h1 to look like
// some lead-in text. The problem is that we're not using h2 as a heading, but
// as lead-in text which would be better if we used the .text-lead class.
h1 + h2,
h1 + p + h2,
h1 + .partner + h2,
.text-lead {
  font-size: px-to-em(18px);
  line-height: 1.4em;
  font-weight: font-weight('regular');
  color: $color-light;
}

@include media-min('medium') {
  h1 {
    font-size: px-to-em(32px);
  }
  h2 {
    font-size: px-to-em(26px);
  }
  h3 {
    font-size: px-to-em(22px);
  }
  h1 + h2,
  h1 + p + h2,
  h1 + .partner + h2 {
    font-size: px-to-em(22px);
  }
}

/**
 * Links
 */

a {
  color: $color-link;
  color: var(--color-link, var(--accent, $color-link));
  text-decoration: none;

  &:hover {
    color: $color-link-hover;
    color: var(--color-link-hover, var(--accent, $color-link-hover));
    text-decoration: underline;
  }
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  color: $color-link;
  color: var(--color-link, var(--accent, $color-link));

  &:hover {
    color: $color-link-hover;
    color: var(--color-link-hover, var(--accent, $color-link-hover));
    text-decoration: none;
  }
}

a[href^="tel"] {
  color: inherit;
  text-decoration: none;

    &:hover {
      color: inherit;
  }
}

/**
 * Other
 */

img {
  border-radius: $border-radius;
}

small {
  display: block;
  line-height: 1.5em;
}

.notice {
  margin: 1.5em 0;
}

.icon.large {
  margin: 0;
  font-size: 4em;
  line-height: 1em;
}

/**
 * Map Wrapper
 */

.map-wrapper,
.iframe-wrapper {
	@include make-video-fluid();

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}

/*==============================================================================
  @Main - #{$filename}
==============================================================================*/

.main {
  background: $bg-main;
  background: var(--bg-main, $bg-main);
  padding: 1em 0;
}

/**
 * Indent Lists
 */

.indent {
  margin: 2em 0;
  list-style: none;
  border-top: 1px solid rgba($black, 0.1);

  > li {
    margin: 0;
    padding: 1em;
    border-bottom: 1px solid rgba($black, 0.1);
  }
}

/**
 * Inline Ads
 */

// This code should eventually be rebuilt to reflect our new widget system where
// it would be considered an inline widget and reflect the markup of all inline
// and sidebar widgets

.partner {
  display: none;
  float: right;
  margin: 0 0 0.5em 1.5em;
  width: 290px;
  max-width: 50%;

  p {
    margin: 0.5em 0;
    color: $color-light;
    font-style: italic;
    text-align: right;
  }
  img {
    margin: 0;
  }
}

// Only display ad on desktop screens
@include media-min('medium') {
  .main .partner {
    display: block;
  }
}
