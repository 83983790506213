// Set file variable
$filename: 'scss/custom/_tpl-promotions.scss';

/*==============================================================================
  @Promotions - #{$filename}
==============================================================================*/

.vs-promo a {
  &:after {
    content: '';
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    width: 44px;
    height: 39px;
    background-image: url('/fs-shared/dist/img/vs-monogram-gold.svg');
    background-size: 44px 39px;
  }

  .experation-date {
    padding-right: 60px;
  }
}

.experation-date {
  @include clearfix();
  font-size: 0.9em;
  color: $color-light;
  align-items: center;

  .chip,
  .timestamp {
    float: left;
    display: inline-block;
    padding: 0.25em 1em;
  }
  
  .chip {
    background: rgba($black, 0.05);
    color: $color-light;
    border-radius: $border-radius;
  }
}
