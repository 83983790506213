// Set file variable
$filename: 'scss/custom/_photo-gallery.scss';

/*==============================================================================
  @Gallery - #{$filename}
==============================================================================*/

.photo-gallery {
  display: flex;
  flex-wrap: wrap;
  margin: -.5rem;
  padding: 2em 0;

  &:after {
    content: '';
    flex-grow: 999999;
  }
}

.photo-gallery__item {
  position: relative;
  margin: 0.5em;
  background: rgba($black, 0.05);
}

.photo-gallery__spacer {
  display: block;
}

.photo-gallery__photo {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  vertical-align: bottom;
}
