// Set file variable
$filename: 'scss/custom/_listing.scss';

/*==============================================================================
  @Listing - #{$filename}
==============================================================================*/

.listing {
  list-style: none;
  margin: 2em 0;
  border-top: 1px solid rgba($black, 0.1);

  > li {
    @include clearfix();
    margin: 0;
    padding: 0.5em 0;
    border-bottom: 1px solid rgba($black, 0.1);
  }
}
@include media-min('medium') {
  .listing > li {
    padding: 1em 0;
  }
}
