// Set file variable
$filename: 'scss/custom/_footer.scss';

/*==============================================================================
  @Footer - #{$filename}
==============================================================================*/

.footer {
  padding: 0;

  > .container {
    padding-bottom: 1em;
  }
}

@include media-min('medium') {
  .footer {
    text-align: left;

    > .container {
      padding-bottom: 2em;
    }
  }
}

/**
 * Footer Widget
 */

.footer .widget {
  padding: 1em 2em;
  margin: 0;

  h3 {
    margin: .5rem 0 1rem;
    padding-bottom: 0.25em;
  }
}

/**
 * Footer Content
 */

.footer-content-wrap {
  padding: 1em 0;
  background: $bg-footer;
  background: var(--bg-footer, $bg-footer);
}

.footer-content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin: 0.5em 0 1em;
}

.footer-content--item {
  width: 100%;
  flex: 1 1 auto;
  margin: 0;
}

.footer-content--item + .footer-content--item,
.loc-hours {
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid rgba($black, 0.1);
}

@include media-min('medium') {
  .footer-content-wrap {
    padding: 2em 0;
  }

  .footer-content {
    flex-direction: row;
    margin: 0 -1em;
  }

  .footer-content--item {
    width: auto;
    margin: 0 1em;
  }

  .footer-content--item + .footer-content--item,
  .loc-hours {
    padding-top: 0;
    margin-top: 0;
    border-top: none;
  }

  .footer-content--item-blog {
    flex-basis: 40%;
  }
}

/**
 * Footer Copyright
 */

.footer-copyright {
  padding: 1em 0;
  color: $color-light;
  text-align: center;

  .flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em 0;

    .flex-item {
      margin: 0;
    }

    .flex-item + .flex-item {
      margin: 1em 0 0 0;
    }
  }

  .sep {
    margin: 0 0.5em;
    color: $color-light;
  }
}

@include media-min('medium') {
  .footer-copyright {
    text-align: left;

    .flex {
      flex-direction: row;
      justify-content: space-between;

      .flex-item {
        margin: 0;
      }

      .flex-item + .flex-item {
        margin: 0 0 0 1em;
      }
    }

    .footer-logo {
      float: right;
    }
  }
}

/**
 * Footer Content
 */

.footer {
  h3 {
    font-size: px-to-em(18px);
    font-weight: font-weight('semi-bold');
  }

  a {
    color: $color-light;

    &:hover {
      color: $accent;
      color: var(--accent, $accent);
    }
  }

  .button {
    @include add-icon-button('left');
  }

  .vcard a {
    color: $color;

    &:hover {
      color: $accent;
      color: var(--accent, $accent);
    }
  }
}

/**
 * Footer Lists
 */

.footer-list {
  list-style: none;
  margin: 0;

  li {
    margin: 1px 0;
  }

  li a {
    display: block;
    padding: 1em;
    border: none;
    border-radius: $border-radius;

    &:hover {
      background: rgba($shade, 0.5);
      color: $accent;
      color: var(--accent, $accent);
      text-decoration: none;
    }
  }

  h3 {
    font-weight: normal;
    margin: 0;
  }

  p {
    margin: 0;
  }
}

@include media-min('medium') {
  .footer-list {
    margin: -0.5em 0 0.5em -1em;
  }
}

/**
 * Location
 */

.footer {

  .loc-content {

    .icon-map-marker,
    .icon-telephone,
    .icon-clock {
      float: left;
      font-size: 1.5em;
      margin-top: 0.1em;
    }

    .adr,
    .tel {
      display: block;
      padding-left: 2em;
    }

    .vcard {
      margin-bottom: 2em;

      p {
        margin: 0;
      }

      p + p {
        margin-top: 1em;
      }
    }
  }

  .loc-hours ul {
    margin: 0;
    list-style: none;
    padding-left: 2em;

    li {
      margin: 0;
    }

    li + li {
      margin-top: 0.5em;
    }
  }
}


@include media-min('medium') {

  .footer {

    .loc-content {
      display: flex;

      > div {
        flex: 1 1 50%;
      }

      .loc-hours {
        flex-basis: 60%;
      }

      > div + div {
        padding-left: 1em;
      }
    }

    .loc-hours {
      display: flex;

      .icon {
        flex-grow: 0;
      }
    }

  }

}

/**
 * Media
 */

.media {
  display: flex !important;
  align-items: center;

  img {
    flex-grow: 0;
    margin: 0;
  }
}

.media-content {
  padding-left: 1.5em;
}

/**
 * Logo
 */

.vs-logo-sec {
  display: block;
  width: 180px;
  height: 47px;
  margin: 0 auto;
  background: url('/fs-shared/dist/img/vs-logo-sec-dark.svg') no-repeat center center;
  background-size: 180px 47px;
  border: none;
  @include text-hide();
}
