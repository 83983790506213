// Set file variable
$filename: 'scss/custom/_tpl-reviews.scss';

/*==============================================================================
  @Reviews - #{$filename}
==============================================================================*/

.review-header {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: px-to-em(18px);
    font-weight: bold;
  }

  .review-date {
    color: $color-light;
    font-style: italic;
    text-align: right;
  }
}

/**
 * Reviews Rating
 */

.review-rating {
  margin: 0 0 1em 0;
  padding: 0.5em 1em;
  text-align: center;
  background: $bg-widget;
  background: var(--bg-widget, var(--shade, $bg-widget));
  border-radius: $border-radius;

  ol.stars {
    margin: 0 0.5em;
  }

  .icon {
    margin: 0;
  }
}

@include media-min('medium') {
  .review-rating {
    float: left;
    display: flex;
    align-items: center;
    margin-right: 1em;
  }
}

/**
 * Reviews Source
 */

.review-source {
  font-style: italic;
  text-align: center;
  color: $color-light;

  a {
    color: $color-light;
  }
}

@include media-min('medium') {
  .review-source {
    line-height: px-to-em(54px);
    text-align: left;
  }
}

/**
 * Review Options
 */

.review-options {
  margin: 1em 0 2em;

  .option {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    margin: 1em 0 0;
    padding: 1em;
    border: 1px solid rgba($black, 0.1);
    border-radius: $border-radius;
    font-size: px-to-em(18px);
    line-height: 1.3em;
    text-align: center;
    color: $color;
    text-transform: uppercase;
    transition: all 0.2s linear;

    p {
      margin: 0;
    }

    * + p {
      margin: 1em 0 0;
    }

    .social-logo {
      display: inline-block;
      margin: 0 auto;

      img {
        margin: 0 auto;
      }
    }

    .button {
      display: block;
      width: 100%;
      text-transform: none;
      font-size: px-to-em(16px, 18px);
    }
  }

  .option:hover {
    background: $white;
    background-clip: padding-box;
    text-decoration: none;
    border-color: rgba($color, 0.25);
    box-shadow: 0 5px 5px rgba($black, 0.1);
    transform: translate(0, -5px);
  }

  .option-good {
    .icon,
    strong {
      color: $green-600;
    }
  }

  .option-bad {
    .icon,
    strong {
      color: $red-600;
    }
  }
}

@include media-min('medium') {
  .review-options {
    display: flex;
    align-items: stretch;
    .option {
      flex: 1 1 50%;
      padding: 2em 1em;
      .button {
        margin-bottom: -(px-to-em(18px, 16px));
      }
    }
    .option + .option {
      margin-left: 1em;
    }
  }
}
